import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { LocalStorage } from '@ngx-pwa/local-storage'
import { User } from '../models/user'
import { Club } from '../models/club'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  search<T>(value: string): Observable<T> {
    return this.http.get<T>(environment.api + '/users/search/'+value+'?limit=100&attributes=id,follower_count,following_count,email,location,first_name,last_name,privacy_profile,fb_identifier,created_at,profile_image_url', {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  get(id: string): Observable<User> {
    return this.http.get<User>(environment.api + '/users/'+id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  getAdministeredClubs(id: string): Observable<Club[]> {
    return this.http.get<Club[]>(environment.api + '/admin/user/'+id+'/administered_clubs', {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  removeUser(id: string): Observable<Object> {
    return this.http.delete(environment.api + '/admin/user/'+id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  pilot(): Observable<Object> {
    return this.http.get(environment.api + '/admin/user/pilot', {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }
}

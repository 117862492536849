import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import {User} from '../../models/user';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {
  users: User[];

  constructor(private user: UserService, private router: Router) { }

  ngOnInit() {
  }

  search(val) {
    const _val = val.toLowerCase();

    this.user.search<Array<User>>(_val).toPromise().then(users => {
      this.users = users;
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-pilot',
  templateUrl: './pilot.component.html'
})
export class PilotComponent implements OnInit {
  geolocation: string

  constructor(private user: UserService) {}

  ngOnInit() {
    this.user.pilot().toPromise().then(response => {
      this.geolocation = response['geolocation']
    })
  }
}

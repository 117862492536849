import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UserService } from '../../services/user.service'
import { Router } from '@angular/router'
import {User} from '../../models/user'

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html'
})
export class UserSearchComponent implements OnInit {
  @Input() exclude: User
  @Input() action: string
  @Output() actionCallback = new EventEmitter()
  users: User[]

  constructor(private user: UserService, private router: Router) { }

  ngOnInit() {
  }

  search(val) {
    const _val = val.toLowerCase()

    this.user.search<Array<User>>(_val).toPromise().then(users => {
      if (this.exclude) {
        users = users.filter(user => user.id != this.exclude.id)
      }
      this.users = users
    })
  }

  userSelected(user) {
    this.actionCallback.emit(user)
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private localStorage: LocalStorage) { }

  login(username: string, password: string) {
    return new Promise((resolve, reject) => {
      this.http.post<User>(environment.api + '/auth/admin_login', {
        email: username,
        password: password
      }).subscribe(
        response => {
          if(response.auth_token != null && response.auth_token != "") {
            window.localStorage.setItem("auth_token", response.auth_token);
            this.localStorage.setItem('user', response).subscribe(() => {
              resolve();
            });
          } else {
            reject();
          }
        },
        error => {
          reject(error);
        });
    })
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  constructor() { }

  loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script')
      scriptElement.src = src
      scriptElement.onload = () => resolve()
      scriptElement.onerror = () => reject(new Error(`Failed to load script: ${src}`))

      document.body.appendChild(scriptElement);
    })
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SurfService } from '../services/surf.service';
import { ActivatedRoute } from '@angular/router';
import { ScriptLoaderService } from '../services/script-loader.service';
import { ViewEncapsulation } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-surf-map',
  templateUrl: './surf-map.component.html',
  styleUrls: ['./surf-map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SurfMapComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document, private scriptLoaderService: ScriptLoaderService, private surfService: SurfService, private route: ActivatedRoute) {
  }

  onMapReady(map) {
    window['var_map'] = map;

    this.scriptLoaderService.loadScript('/assets/surf.js').then(() => {
    }).catch(() => {
      console.log('surf js load failed');
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      window['surf_username'] = params['name'];
      window['surf_location'] = params['location'];
      this.surfService.visualiserSurfs(params['id']).toPromise()
        .then(data => {
          //console.log(JSON.stringify(data.raw, null, 2));
          window['var_data'] = data;
        }).catch(error => {
          console.log(error);
        })
    });
  }
}

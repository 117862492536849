import { ExportToCsv } from 'export-to-csv'

export const generateCSV = (filename, data) => {
  if (!data.length) {
    return
  }
  data.forEach(item => {
    Object.keys(item).forEach(k => {
      if (item[k] === null) {
        item[k] = ''
      }
    })
  })
  const csvExporter = new ExportToCsv({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename
  })
  csvExporter.generateCsv(data)
}

import { Component, OnInit } from '@angular/core';
import { ClubService } from '../services/club.service';
import { ActivatedRoute } from '@angular/router';
import { User } from '../models/user';
import { Club } from '../models/club';

@Component({
  selector: 'app-club-edit',
  templateUrl: './club-edit.component.html'
})
export class ClubEditComponent implements OnInit {
  editing: Boolean = false;
  club: Club = {} as any;
  admin: User = {} as any;
  members: User[] = [];

  constructor(private clubService: ClubService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.clubService.get(params['id']).toPromise()
        .then(club => {
          this.club = club;
          return this.clubService.getMembers(this.club.id).toPromise();
        }).then(members => {
          this.members = members;
          return this.clubService.getAdmin(this.club.id).toPromise();
        }).then(admin => {
          this.admin = admin;
        }).catch(error => {
          console.log(error);
        })
    });
  }

  editAdmin() {
    this.editing = true
  }
  saveAdmin() {
    this.clubService.setAdmin(this.club.id, this.admin.email).toPromise()
      .then(admin => {
        this.editing = false
        this.admin = admin
      }).catch(res => {
        if (res.error && res.error.error) {
          const error = res.error.error
          alert(error.message || error.code)
        } else {
          console.log(res)
        }
      })
  }

  removeFromClubHandler(user) {
    user.removing = true
    this.clubService.removeUserFromClub(this.club.id, user.id).toPromise()
      .then(() => {
        this.members = this.members.filter(member => member.id !== user.id)
      }).catch(res => {
        user.removing = false
        if (res.error && res.error.error) {
          const error = res.error.error
          alert(error.message || error.code)
        } else {
          console.log(res)
        }
      })
  }

  removeClub() {
    this.clubService.removeClub(this.club.id).toPromise()
      .then(() => {
        window.location.href = '/'
      }).catch(res => {
        if (res.error && res.error.error) {
          const error = res.error.error
          alert(error.message || error.code)
        } else {
          console.log(res)
        }
      })
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Club } from '../models/club';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  constructor(private http: HttpClient) { }

  get(id: string): Observable<Club> {
    return this.http.get<Club>(environment.api + '/clubs/'+id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    });
  }

  getMembers(id: string): Observable<User[]> {
    return this.http.get<User[]>(environment.api + '/clubs/'+id+'/members', {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    });
  }

  getAdmin(id: string): Observable<User> {
    return this.http.get<User>(environment.api + '/admin/club/'+id+'/administrator', {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    });
  }

  setAdmin(club_id: string, email: string): Observable<User> {
    return this.http.put<User>(environment.api + '/admin/club/'+club_id+'/administrator', {
      email: email
    }, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    });
  }

  removeUserFromClub(club_id: string, user_id: string): Observable<Object> {
    return this.http.delete(environment.api + '/admin/club/'+club_id+'/user/'+user_id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    });
  }

  removeClub(club_id: string): Observable<Object> {
    return this.http.delete(environment.api + '/admin/club/'+club_id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    });
  }
}

import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { SurfService } from '../services/surf.service'
import { generateCSV } from '../utilities/exporter'

@Component({
  selector: 'app-exporter',
  templateUrl: './exporter.component.html'
})
export class ExporterComponent implements OnInit {
  isExporting = false
  fromDate = new FormControl(new Date())
  toDate = new FormControl(new Date())

  constructor(private surf: SurfService) {}

  ngOnInit() {}

  exportHandler() {
    if (this.fromDate.value == null || this.toDate.value == null) {
      return
    }
    this.isExporting = true

    const from = this.fromDate.value.toISOString()
    const to = this.toDate.value.toISOString()

    this.surf.search(from, to).toPromise().then(surfs => {
      this.isExporting = false

      generateCSV('surfs', surfs)
    }).catch(error => {
      console.log(error)
      this.isExporting = false
    })
  }
}

import { Component, OnInit } from '@angular/core'
import { UserService } from '../../services/user.service'
import { SurfService } from '../../services/surf.service'
import { ActivatedRoute } from '@angular/router'
import { User } from '../../models/user'
import { Club } from '../../models/club'
import { Surf } from '../../models/surf'

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit {
  user: User = {} as any
  clubs: Club[] = []
  surfs: Surf[]

  constructor(private userService: UserService, private surfService: SurfService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.userService.get(params['id']).toPromise()
        .then(user => {
          this.user = user
          return this.userService.getAdministeredClubs(this.user.id).toPromise()
        }).then(clubs => {
          this.clubs = clubs
          return this.surfService.feeds(this.user.id).toPromise()
        }).then(surfs => {
          this.surfs = surfs
        }).catch(error => {
          console.log(error)
        })
    })
  }

  removeUser() {
    if (!confirm('Are you sure you would like to delete this user?')) {
      return
    }
    this.userService.removeUser(this.user.id).toPromise().then(() => {
      window.location.href = '/'
    }).catch(res => {
      if (res.error && res.error.error) {
        const error = res.error.error
        alert(error.message || error.code)
      } else {
        console.log(res)
      }
    })
  }

  migrateSurfs(user) {
    const name = user => {
      const text = (user.first_name+' '+user.last_name).trim()
      return user.email ? text+' ('+user.email+')' : text
    }
    if (confirm('Are you sure you would like to migrate surfs from '+name(this.user)+' to '+name(user)+'?')) {
      this.surfService.migrate(this.user.id, user.id).toPromise().then(() => {
        this.surfService.feeds(this.user.id).toPromise().then(surfs => {
          this.surfs = surfs
        })
      }).catch(res => {
        if (res.error && res.error.error) {
          const error = res.error.error
          alert(error.message || error.code)
        } else {
          console.log(res)
        }
      })
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NguiMapModule } from '@ngui/map';


import { AuthService } from './services/auth.service';
import { StorageModule } from '@ngx-pwa/local-storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { ClubEditComponent } from './club/club-edit.component';
import { SurfMapComponent } from './surf/surf-map.component';
import { ExporterComponent } from './exporter/exporter.component';
import { UserSearchComponent } from './user/user-search/user-search.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { SpotListComponent } from './spot/spot-list.component';
import { PilotComponent } from './pilot/pilot.component';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ClubEditComponent,
    SurfMapComponent,
    ExporterComponent,
    UserSearchComponent,
    UserEditComponent,
    UserListComponent,
    SpotListComponent,
    PilotComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'spots', component: SpotListComponent },
      { path: 'club/:id', component: ClubEditComponent },
      { path: 'surf/:id/:name/:location', component: SurfMapComponent},
      { path: 'user/:id', component: UserEditComponent },
      { path: 'users', component: UserListComponent },
      { path: '**', component: UserListComponent }
    ]),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StorageModule.forRoot({ IDBNoWrap: true }),
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyDDuMLhQRsqMPUQro5Dlt-AFjMj7vYAP3M&libraries=geometry&v=3.51'})
  ],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

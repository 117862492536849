import { Component, OnInit } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { LocalStorage } from '@ngx-pwa/local-storage'

import { SpotService } from './services/spot.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Ripcurl Dashboard';
  isLogin = false;
  isLoggedIn = false;
  links = [];

  constructor(private router: Router, private localStorage: LocalStorage, private spotService: SpotService) {}

  ngOnInit() {
    this.router.events.subscribe(val => {
      if(val instanceof NavigationStart) {
        if(val.url == '/login') {
          this.isLogin = true;
          this.links = [];
        } else {
          this.isLogin = false;
          this.links = [{text: 'Users', href: '/'}, {text: 'Spots', href: '/spots'}];
          this.localStorage.getItem('user').subscribe(user => {
            if(!user) {
              this.isLoggedIn = false;
              this.router.navigate(['/login']);
            } else {
              this.isLoggedIn = true;
            }
          });
        }
      }
    });
  }

  navigationClick(link) {
    this.spotService.navigationNotify(link)
  }

  logoutHandler() {
    this.localStorage.removeItem('user').subscribe(() => {
      this.isLoggedIn = false;
      this.router.navigate(['/login']);
    });
  }
}

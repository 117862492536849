import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { LocalStorage } from '@ngx-pwa/local-storage'
import { Spot } from '../models/spot'
import { Club } from '../models/club'
import { Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SpotService {
  private notify = new Subject<any>()

  notifyObservable$ = this.notify.asObservable()

  constructor(private http: HttpClient) { }

  load<T>(type: string): Observable<T> {
    return this.http.get<T>(environment.api + '/admin/'+type, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  get(id: string): Observable<Spot> {
    return this.http.get<Spot>(environment.api + '/spots/'+id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  save(data): Observable<Spot> {
    return this.http.post<Spot>(environment.api + '/admin/spots', data, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  ignore(name: string, latLng_id: string, latitude: string, longitude: string): Observable<Object> {
    return this.http.post(environment.api + '/admin/surfs', {
      name,
      latLng_id,
      latitude,
      longitude
    }, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  remove(id: string): Observable<Object> {
    return this.http.delete(environment.api + '/admin/spots/'+id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  removeIgnored(id: string): Observable<Object> {
    return this.http.delete(environment.api + '/admin/ignored/'+id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  navigationNotify(data: any) {
    if (data) {
      this.notify.next(data)
    }
  }

  csv<T>(): Observable<T> {
    return this.http.get<T>(environment.api + '/admin/spots/csv', {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { isEqual } from 'lodash'

import { SpotService } from '../services/spot.service'
import { Spot } from '../models/spot'
import { IgnoredSurfName } from '../models/ignoredSurfName'

import { generateCSV } from '../utilities/exporter'

interface SearchData {
    [key:string]: Array<Spot>
}
interface IgnoredSearchData {
    [key:string]: Array<IgnoredSurfName>
}

@Component({
  selector: 'app-spot-list',
  templateUrl: './spot-list.component.html'
})
export class SpotListComponent implements OnInit {
  @Input() action: string
  results: object[]
  ignored: object[] = []
  spots: object[] = []
  surfs: object[] = []
  markers: object[] = []
  circles: object[] = []
  searchText = new FormControl()
  customNameCount = new FormControl()
  newSpot_stage = 0
  newSpot = new FormGroup({
    type: new FormControl("name"),
    name: new FormControl(),
    beach: new FormControl()
  })
  editName = new FormControl()
  editBeachID = new FormControl()
  loading = 0
  surflineWithoutName = true
  map
  lastEvent
  selectedId
  lastBounds
  redrawTimeout

  private subscription: Subscription

  constructor(private spotService: SpotService, private router: Router) { }

  ngOnInit() {
    this.subscription = this.spotService.notifyObservable$.subscribe((res) => {
      if (res.text == 'Spots') {
        this.searchText.setValue('')

        this.redraw()
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  numberOnly(event): boolean {
    const charCode = event.which || event.keyCode
    return !(charCode > 31 && (charCode < 48 || charCode > 57))
  }

  call(method, args) {
    this.loading += 1
    return this.spotService[method](...args).toPromise().catch(res => {
      if (res.error && res.error.error) {
        const error = res.error.error
        alert(error.message || error.code)
      } else {
        console.log(res)
      }
      throw res
    }).finally(() => {
      this.loading -= 1
    })
  }
  load() {
    return Promise.all([
      this.call('load', ['spots']).then(data => {
        data.spots.forEach(spot => {
          spot['type'] = 'latLng'
        })
        this.spots = this.spots.concat(data.spots)
        this.redraw()
      }),
      this.call('load', ['surfs']).then(data => {
        this.ignored = this.ignored.concat(data.ignored)

        this.surfs = this.surfs.concat(data.surfs)
        this.redraw()
      })
    ])
  }
  calculateDistance(item1, item2) {
    if ((Math.abs(item1['latitude'] - item2['latitude']) > 0.023) ||
        (item1['latitude'] > -40 && item1['latitude'] < 40 && Math.abs(item1['longitude'] - item2['longitude']) > 0.03)) {
      return 3
    }
    const least = (a, b) => a < b ? a : b
    const radians = degrees => (degrees * Math.PI) / 180.0
    return 6371 * Math.acos(least(1,
      Math.cos(radians(item1['latitude'])) * Math.cos(radians(item2['latitude'])) * Math.cos(radians(item1['longitude']) - radians(item2['longitude'])) + Math.sin(radians(item1['latitude'])) * Math.sin(radians(item2['latitude']))
    ))
  }
  isDistanceClose(distance) {
    return distance < 2
  }
  closeTogether(item1, item2) {
    const distance = this.calculateDistance(item1, item2)
    return this.isDistanceClose(distance)
  }
  toggleNewSpot() {
    this.newSpot_stage = this.newSpot_stage == 0 ? 1 : 0
  }
  addNewSpotFromNothing() {
    if (this.newSpot.value.type != 'watch' && !this.newSpot.value.name) {
      alert('Name required')
    } else if (this.newSpot.value.type != 'name' && !this.newSpot.value.beach) {
      alert('Beach ID required')
    } else {
      this.newSpot_stage = 2
    }
  }
  toggleSurflineWithoutName() {
    this.surflineWithoutName = !this.surflineWithoutName
    this.redraw()
  }
  editResult(result) {
    this.results.forEach(loopResult => {
      loopResult['editing'] = false
    })
    result['editing'] = true
    this.editName.setValue(result.nameSpot)
    this.editBeachID.setValue(result.beach_id)
  }
  cancelEditResult(result) {
    delete result['editing']
  }
  saveResult(result) {
    this.call('save', [{
      name: this.editName.value,
      beach_id: this.editBeachID.value,
      [result.typesString == 'Surf' ? 'surf_id' : 'id']: result.id
    }]).then(spot => {
      this.handleSpotChange(result.id, spot)
    })
  }
  distributeSurfs(visibleSurfs, visibleSpots) {
    this.ignored.forEach(ignoredItem => {
      ignoredItem['count'] = 0
    })
    const surfs = []
    visibleSurfs.forEach(surf => {
      const attachSurfTo = dest => {
        if (!dest['surfs']) {
          dest['surfs'] = {}
          dest['surfs_norm'] = {}
        }
        let surf_name = surf['name'].trim()
        const surf_name_normalized = surf_name.toLowerCase()
        if (dest['surfs_norm'][surf_name_normalized] !== undefined) {
          surf_name = dest['surfs_norm'][surf_name_normalized]
        } else {
          dest['surfs_norm'][surf_name_normalized] = surf_name
        }
        if (dest['surfs'][surf_name] !== undefined) {
          dest['surfs'][surf_name] += 1
        } else {
          dest['surfs'][surf_name] = 1
        }
        dest['totalCount'] = (dest['totalCount'] || 0) + 1
      }
      const getClosestTo = (type, items) => {
        let closestDistance
        let closestItem
        items.forEach(item => {
          const distance = this.calculateDistance(surf, item)
          if (this.isDistanceClose(distance)) {
            if (closestItem === undefined || distance < closestDistance) {
              if (type == 'spots') {
                const ignoredItem = this.ignored.find(ignoredItem => ignoredItem['latLng_id'] == item['id'] && ignoredItem['name'] == surf['name'])
                if (ignoredItem) {
                  ignoredItem['count'] = (ignoredItem['count'] || 0) + 1
                  return
                }
              }
              closestItem = item
              closestDistance = distance
            }
          }
        })
        return closestItem
      }

      const ignoredItem = this.ignored.find(item => !item['latLng_id'] && item['name'] == surf['name'] && this.closeTogether(surf, item))
      if (ignoredItem) {
        ignoredItem['count'] += 1
        return
      }
      const spot = getClosestTo('spots', visibleSpots)
      if (spot) {
        attachSurfTo(spot)
        return
      }

      let closestSurf = getClosestTo('surfs', surfs)
      if (!closestSurf) {
        closestSurf = {
          id: surf['id'],
          type: 'surf',
          latitude: surf['latitude'],
          longitude: surf['longitude']
        }
        surfs.push(closestSurf)
      }
      attachSurfTo(closestSurf)
    })

    const _customNameCount = parseInt(this.customNameCount.value) || 0

    const data = {}
    const loop = (key, items) => {
      if (_customNameCount) {
        items = items.filter(item => item.totalCount >= _customNameCount)
      }
      items.forEach(item => {
        item['position'] = {
          lat: item['latitude'],
          lng: item['longitude']
        }
      })
      data[key] = items
    }
    loop('surfs', surfs)
    loop('spots', visibleSpots)
    return data
  }
  search() {
    this.selectedId = null
    this.redraw()
  }
  redraw($event = null) {
    if ($event) {
      this.lastEvent = $event

      const first_map = !this.map
      this.map = $event.target
      const bounds = this.map.getBounds()
      const jsonBounds = JSON.stringify(bounds)
      const moved = this.lastBounds != jsonBounds
      this.lastBounds = jsonBounds
      if (first_map) {
        this.load()
      } else {
        if (this.redrawTimeout) {
          clearTimeout(this.redrawTimeout)
        }
        if (moved) {
          this.redrawTimeout = setTimeout(() => {
            this.redraw()
          }, 1000)
        }
      }
      return
    } else {
      $event = this.lastEvent
      if (!$event) {
        return
      }
    }
    if (!this.spots) {
      return
    }

    const bounds = this.map.getBounds()
    const ne = bounds.getNorthEast()
    const ne_lat = ne.lat()
    const ne_lng = ne.lng()
    const sw = bounds.getSouthWest()
    const sw_lat = sw.lat()
    const sw_lng = sw.lng()
    const visible = item => {
      const compare = (axis, v2, direction) => {
        const v1 = item[axis == 'lat' ? 'latitude' : 'longitude']
        return direction == 1 ? (v1 > v2) : (v1 < v2)
      }
      if (sw_lat > 0 && ne_lat < 0) {
        if (!(compare('lat', sw_lat, 1) || compare('lat', ne_lat, -1))) {
          return false
        }
      } else {
        if (!(compare('lat', sw_lat, 1) && compare('lat', ne_lat, -1))) {
          return false
        }
      }
      if (sw_lng > 0 && ne_lng < 0) {
        if (!(compare('lng', sw_lng, 1) || compare('lng', ne_lng, -1))) {
          return false
        }
      } else {
        if (!(compare('lng', sw_lng, 1) && compare('lng', ne_lng, -1))) {
          return false
        }
      }
      return true
    }

    const searchText = this.searchText.value && this.searchText.value.toLowerCase()
    const count = this.customNameCount.value
    const filterBySearchText = items => {
      if (searchText) {
        const searchTextIsBeachID = searchText && /^[\d,\s]+$/.test(searchText)
        if (searchTextIsBeachID) {
          const searchIDs = searchText.split(',').map(id => id.trim())
          return items.filter(result => {
            if (!result['beach_id']) {
              return false
            }
            return searchIDs.every(id => result['beach_id'].includes(id))
          })
        } else {
          return items.filter(result => {
            const location = result['nameSpot'] || result['surflineSpot']
            return (
              (location && location.toLowerCase().includes(searchText)) ||
              (result['surfs_norm'] && Object.keys(result['surfs_norm']).some(name => name.includes(searchText)))
            )
          })
        }
      }
      return items
    }
    let filteredSurfs = filterBySearchText(this.surfs)
    let filteredSpots = filterBySearchText(this.spots)
    let data = {}
    if (!searchText && !count) {
      filteredSurfs = filteredSurfs.filter(visible)
      filteredSpots = filteredSpots.filter(visible)

      data = this.distributeSurfs(filteredSurfs, filteredSpots)

      if (this.selectedId) {
        this.results = data['surfs'].concat(data['spots'])
        this.results = this.results.filter(result => result['id'] === this.selectedId)
      } else {
        this.results = []
      }
    } else {
      if (count) {
        filteredSurfs = filteredSurfs.filter(visible)
        filteredSpots = filteredSpots.filter(visible)
      }
      data = this.distributeSurfs(filteredSurfs, filteredSpots)
      if (count) {
        const countFilter = item => item['totalCount'] > count
        data['surfs'] = data['surfs'].filter(countFilter)
        data['spots'] = data['spots'].filter(countFilter)
      }

      this.results = data['surfs'].concat(data['spots'])
      if (this.selectedId) {
        this.results = this.results.filter(result => result['id'] === this.selectedId)
      } else if (!searchText && !count) {
        this.results = []
      }
      this.results = this.results.slice(0, 100)

      if (!count) {
        data['surfs'] = data['surfs'].filter(visible)
        data['spots'] = data['spots'].filter(visible)
      }
    }
    this.results.forEach(result => {
      if (result['types']) {
        result['typesString'] = result['types'].map(type => type.charAt(0).toUpperCase() + type.slice(1)).join(', ')
      } else {
        result['typesString'] = 'Surf'
      }
    })

    data['surfs'].forEach(surf => {
      surf['icon'] = 'https://maps.google.com/mapfiles/ms/icons/'+(surf['id'] == this.selectedId ? 'blue' : 'red')+'-dot.png'
    })
    if (!isEqual(data['surfs'], this.markers)) {
      this.markers = data['surfs']
    }

    const circles = []
    data['spots'].forEach(circle => {
      const surflineWithoutName = circle['types'].includes('surfline') && !circle['types'].includes('name')
      if (this.map.zoom > 8) {
        circles.push({
          ...circle,
          strokeColor: circle['id'] == this.selectedId ? '#00f' : (surflineWithoutName ? '#f00' : '#019875'),
          strokeOpacity: 0.8,
          radius: 100
        })
      }
      if (this.map.zoom > 5 && surflineWithoutName && this.surflineWithoutName) {
        circles.push({
          ...circle,
          strokeColor: '#f00',
          strokeOpacity: 0.8,
          fillOpacity: 0,
          radius: 2000,
          clickable: false
        })
      }
    })
    if (this.map.zoom > 5) {
      data['spots'].filter(spot => spot['types'].includes('name')).forEach(circle => {
        circles.push({
          ...circle,
          strokeColor: '#019875',
          strokeOpacity: 0.8,
          fillOpacity: 0,
          radius: 2000,
          clickable: false
        })
      })
    }
    data['spots'].filter(spot => spot['types'].includes('surfline')).forEach(circle => {
      circles.push({
        ...circle,
        strokeColor: '#00f',
        strokeOpacity: 0.8,
        fillOpacity: 0,
        radius: 25000,
        clickable: false
      })
    })
    if (!isEqual(circles, this.circles)) {
      this.circles = circles
    }
  }
  handleSpotChange(id, spot) {
    if (spot.id) {
      const existingIndex = this.spots.findIndex(loopSpot => loopSpot['id'] === spot['id'])
      if (existingIndex !== -1) {
        // Modify
        const newSpots = [...this.spots]
        newSpots[existingIndex] = spot
        this.spots = newSpots
      } else {
        // Add
        this.spots = [...this.spots, spot]
        this.selectedId = spot.id
      }
    } else {
      if (id) {
        // Remove
        this.spots = this.spots.filter(spot => spot['id'] != id)
      } else {
        return
      }
    }
    this.redraw()
  }
  addNewSpotFromClick($event) {
    if (this.newSpot_stage != 2 || !$event.latLng) {
      return
    }
    const types = this.newSpot.value.type.split("_and_")
    const spot = {
      name: types.includes("name") && this.newSpot.value.name,
      beach: types.includes("watch") && this.newSpot.value.beach,
      latitude: $event.latLng.lat(),
      longitude: $event.latLng.lng()
    }
    this.call('save', [spot]).then(spot => {
      this.handleSpotChange(null, spot)
    }).then(() => {
      this.newSpot_stage = 0
    })
  }

  markerClick(result) {
    if (this.map.zoom < 10) {
      this.flyTo(result, 10)
    } else {
      this.selectedId = result.id
      this.redraw()
    }
  }
  ignoreSurf(result, name) {
    if (!confirm('Are you sure you would like to ignore "'+name+'"?')) {
      return
    }
    const latLng_id = result.type == 'latLng' ? result.id : undefined
    this.call('ignore', [name, latLng_id, result.latitude, result.longitude]).then(ignored => {
      delete result.surfs[name]
      if (!Object.keys(result['surfs']).length) {
        delete result['surfs']
        if (result.type == 'surf') {
          this.results = this.results.filter(item => item['id'] !== result['id'])
          this.markers = this.markers.filter(surf => surf['id'] !== result['id'])
        }
      }
      this.ignored.push(ignored)

      this.redraw()
    })
  }
  removeSpot(spotToRemove) {
    if (!confirm('Are you sure you would like to delete '+(spotToRemove.name ? '"'+spotToRemove.name+'"' : 'this spot')+'?')) {
      return
    }
    this.call('remove', [spotToRemove.id]).then(() => {
      this.handleSpotChange(spotToRemove.id, {})
    })
  }
  flyTo(result, zoom) {
    if (this.map.zoom < zoom) {
      this.map.setZoom(zoom)
    }
    this.map.setCenter({
      lat: result.latitude,
      lng: result.longitude
    })
    this.redraw()
  }

  ignoredSearchText = new FormControl()
  ignoredSurfNames: object[]

  ignoredSearch() {
    this.ignored.forEach(item => {
      if (item['latLng_id']) {
        const spot = this.spots.find(spot => spot['id'] == item['latLng_id'])
        item['location'] = spot['surflineSpot'] || spot['nameSpot']
      }
    })
    this.ignoredSurfNames = this.ignored

    const _val = this.ignoredSearchText.value && this.ignoredSearchText.value.toLowerCase()
    if (_val) {
      this.ignoredSurfNames = this.ignoredSurfNames.filter(item => item['name'].toLowerCase().includes(_val))
    }
  }
  removeIgnored(ignoredSurfName) {
    if (!confirm('Are you sure you would like to no longer ignore "'+ignoredSurfName['name']+'"?')) {
      return
    }
    this.spotService.removeIgnored(ignoredSurfName['id']).toPromise().then(() => {
      this.ignored = this.ignored.filter(currentSpot => currentSpot['id'] !== ignoredSurfName['id'])
      this.ignoredSurfNames = this.ignoredSurfNames.filter(currentSpot => currentSpot['id'] !== ignoredSurfName['id'])

      let results
      if (ignoredSurfName.latLng_id) {
        const result = this.spots.find(spot => spot['id'] == ignoredSurfName.latLng_id)
        if (result) {
          results = [result]
        }
      } else {
        results = this.spots.concat(this.surfs).filter(item => {
          return item['surfs_norm'] && Object.keys(item['surfs_norm']).includes(ignoredSurfName['name'].toLowerCase()) && this.closeTogether(ignoredSurfName, item)
        })
      }
      this.selectedId = results.length ? results[0].id : undefined
      this.redraw()
    }).catch(res => {
      if (res.error && res.error.error) {
        const error = res.error.error
        alert(error.message || error.code)
      } else {
        console.log(res)
      }
    })
  }

  isExporting = false

  exportHandler() {
    this.isExporting = true

    this.spotService.csv().toPromise().then(latLngs => {
      this.isExporting = false

      generateCSV('spots', latLngs)
    }).catch(error => {
      console.log(error)
      this.isExporting = false
    })
  }
}

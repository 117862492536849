import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {environment} from '../../environments/environment'
import { Observable } from 'rxjs'
import { Surf } from '../models/surf'

@Injectable({
  providedIn: 'root'
})
export class SurfService {

  constructor(private http: HttpClient) { }

  search(from: string, to: string) {
    return this.http.get<any[]>(environment.api + '/admin/export/surfs/'+from+'/'+to, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  visualiserSurfs(id: string) {
    return this.http.get<any>(environment.api + '/admin/visualiser_surfs/'+id+'/waves', {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  feeds(user_id: string): Observable<Surf[]> {
    return this.http.get<Surf[]>(environment.api + '/admin/feeds/'+user_id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }

  migrate(from_user_id: string, to_user_id: string) {
    return this.http.get<Surf[]>(environment.api + '/admin/migrate_surfs/'+from_user_id+'/'+to_user_id, {
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("auth_token")
      }
    })
  }
}
